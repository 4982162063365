import { Link } from '@remix-run/react'

export function Header() {
  return (
    <div className="navbar justify-between">
      <div className="navbar-start -mx-8 md:mx-0">
        <Link to={`/`} className="flex flex-row items-center">
          <img
            src="https://eu-central-1-shared-euc1-02.graphassets.com/cluk77jyt0m4k07w108ezfwe1/resize=height:64/auto_image/clus8s6ex5aey08w1lxva3wgl"
            alt="Vatomat"
            className="m-2 h-12 md:h-16"
          />
          <div>
            <h3 className="text-2xl font-bold md:text-3xl">Vatomat</h3>
            <div className="text-xs opacity-60 md:text-sm">
              Víme, jak na vatu
            </div>
          </div>
        </Link>
      </div>
      <div className="navbar-end md:hidden">
        <div className="dropdown dropdown-end">
          <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </div>
          <ul
            tabIndex={0}
            className="menu dropdown-content menu-sm z-[1] mt-3 w-52 rounded-box bg-base-100 p-2 shadow"
          >
            <li>
              <Link to={`/kontakt`}>Kontakt</Link>
            </li>
            <li>
              <Link to={`/o-nas`}>O nás</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="navbar-end hidden md:flex">
        <ul className="menu menu-horizontal px-1">
          <li>
            <Link to={`/kontakt`}>Kontakt</Link>
          </li>
          <li>
            <Link to={`/o-nas`}>O nás</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}
